import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { InView } from 'react-intersection-observer';

import HomeCollectionCarousel from '../components/homeCollectionCarousel';
import HomeSectionBanner from '../components/homeSectionBanner';
import HomeSectionCarousel from '../components/homeSectionCarousel';
import Layout from '../components/layout';
import { Seo } from '../components/seo';
import IconDownWhite from '../images/icon-down-white.svg';
import joinClassNames from '../utilities/joinClassNames';

const IndexPage = ({ data }) => {
  const [animated, setAnimated] = useState(false);
  const [navbarTheme, setNavbarTheme] = useState('home');

  const animateHeader = (offset) => {
    if (offset > 0) {
      setAnimated(true);
    } else {
      setNavbarTheme('home');
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', () => {
        animateHeader(window.scrollY);
      });
    }
  }, []);

  const scrollIntoView = (elementId) => {
    document
      .getElementById(elementId)
      .scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  return (
    <Layout navbarTheme={navbarTheme}>
      <main>
        <InView
          as="header"
          onChange={(inView) => {
            if (inView) {
              setNavbarTheme('home');
            }
          }}
          className={joinClassNames(
            animated ? 'animated' : '',
            'h-[90vh] md:h-screen pt-16 px-8 flex flex-col bg-grayDark'
          )}
          id="header"
        >
          <div className="relative grow overflow-hidden">
            {/* Top-left */}
            <div
              className="absolute z-10 w-1/2 h-1/2 home-header-top-left"
              style={{
                backgroundImage:
                  'linear-gradient(to bottom right, #56585A 50%, transparent 0)',
              }}
            />

            {/* Top-right */}
            <div
              className="absolute right-0 z-10 w-1/2 h-1/2 home-header-top-right"
              style={{
                backgroundImage:
                  'linear-gradient(to bottom left, #56585A 50%, transparent 0)',
              }}
            />

            {/* Bottom-left */}
            <div
              className="absolute bottom-0 z-10 w-1/2 h-1/2 home-header-bottom-left"
              style={{
                backgroundImage:
                  'linear-gradient(to top right, #56585A 50%, transparent 0)',
              }}
            />

            {/* Bottom-right */}
            <div
              className="absolute bottom-0 right-0 z-10 w-1/2 h-1/2 home-header-bottom-right"
              style={{
                backgroundImage:
                  'linear-gradient(to top left, #56585A 50%, transparent 0)',
              }}
            />

            <GatsbyImage
              className="absolute inset-0"
              alt={data.contentfulHomePage.headerSummary}
              image={data.contentfulHomePage.headerImage.gatsbyImage}
              loading={'eager'}
            />
          </div>

          <div className="h-16 flex items-center">
            <h1 className="mr-auto text-white">
              {data.contentfulHomePage.headerSummary}
            </h1>

            <button onClick={() => scrollIntoView('section0')}>
              <img src={IconDownWhite} alt="Scroll Down" className="h-4" />
            </button>
          </div>
        </InView>

        {data.contentfulHomePage.sections.map((section, index) => (
          <React.Fragment key={index}>
            {(section.internal.type === 'ContentfulArt' ||
              section.internal.type === 'ContentfulSee' ||
              section.internal.type === 'ContentfulEvent') && (
              <>
                {section.previewDisplay === 'Image' ? (
                  <HomeSectionBanner
                    section={section}
                    index={index}
                    setNavbarTheme={setNavbarTheme}
                    isLast={
                      index === data.contentfulHomePage.sections.length - 1
                    }
                    scrollIntoView={scrollIntoView}
                  />
                ) : (
                  <HomeSectionCarousel
                    section={section}
                    index={index}
                    setNavbarTheme={setNavbarTheme}
                    isLast={
                      index === data.contentfulHomePage.sections.length - 1
                    }
                    scrollIntoView={scrollIntoView}
                  />
                )}
              </>
            )}

            {(section.internal.type === 'ContentfulAllArt' ||
              section.internal.type === 'ContentfulAllSee') && (
              <HomeCollectionCarousel
                index={index}
                setNavbarTheme={setNavbarTheme}
                isLast={index === data.contentfulHomePage.sections.length - 1}
                scrollIntoView={scrollIntoView}
                collection={
                  section.internal.type === 'ContentfulAllArt'
                    ? data.allContentfulArt
                    : data.allContentfulSee
                }
              />
            )}
          </React.Fragment>
        ))}
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head = ({ data }) => (
  <Seo
    title={data.contentfulHomePage.meta.title}
    description={data.contentfulHomePage.meta.description}
  />
);

export const query = graphql`
  {
    contentfulHomePage(contentful_id: { eq: "17wHoEmL60nlf17Q9IFGUw" }) {
      meta {
        title
        description
      }
      headerImage {
        gatsbyImage(width: 2400)
      }
      headerSummary
      sections {
        ... on Node {
          ... on ContentfulArt {
            slug
            featuredImage {
              gatsbyImage(width: 2400)
            }
            highlightColor
            previewDisplay
            images {
              gatsbyImage(width: 2400)
            }
            internal {
              type
            }
            summary
          }
          ... on ContentfulSee {
            slug
            featuredImage {
              gatsbyImage(width: 2400)
            }
            highlightColor
            previewDisplay
            images {
              gatsbyImage(width: 2400)
            }
            internal {
              type
            }
            summary
          }
          ... on ContentfulEvent {
            association {
              slug
              internal {
                type
              }
            }
            featuredImage {
              gatsbyImage(width: 2400)
            }
            highlightColor
            previewDisplay
            images {
              gatsbyImage(width: 2400)
            }
            internal {
              type
            }
            summary
          }
          ... on ContentfulAllArt {
            internal {
              type
            }
          }
          ... on ContentfulAllSee {
            internal {
              type
            }
          }
        }
      }
    }
    allContentfulArt(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        title
        featuredImage {
          gatsbyImage(width: 2400)
        }
        internal {
          type
        }
        highlightColor
      }
    }
    allContentfulSee(sort: { fields: updatedAt, order: DESC }) {
      nodes {
        title
        featuredImage {
          gatsbyImage(width: 2400)
        }
        internal {
          type
        }
        highlightColor
      }
    }
  }
`;
