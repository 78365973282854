import React, {useRef} from "react"
import {Link} from "gatsby";
import joinClassNames from "../utilities/joinClassNames";
import {GatsbyImage} from "gatsby-plugin-image";
import IconDownWhite from "../images/icon-down-white.svg";
import IconDownYellow from "../images/icon-down-yellow.svg";
import IconDownBlack from "../images/icon-down-black.svg";
import {InView} from "react-intersection-observer";
import {Swiper, SwiperSlide} from "swiper/react";

export default function HomeSectionCarousel({ section, setNavbarTheme, index, isLast, scrollIntoView }) {
  const swiperRef = useRef();

  return (
    <InView
      as="section"
      threshold={0.9}
      onChange={(inView) => {
        if (
          inView &&
          (section.highlightColor === 'White' ||
            section.highlightColor === 'Yellow')
        ) {
          setNavbarTheme('black');
        } else if (inView) {
          setNavbarTheme('home');
        }
      }}
      className={joinClassNames(
        `bg-${section.highlightColor.toLowerCase()}`,
        'h-[90vh] md:h-screen pt-16 px-8 flex flex-col'
      )}
      id={`section${index}`}
      key={index}
    >
      <div className="relative grow">
        <Swiper
          slidesPerView={1}
          loop={true}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          className="relative w-full h-full"
        >
          {section.images.map((sect, index) => (
            <SwiperSlide key={index} className="w-full h-full">
              <Link
                className={`cursor-${sect.highlightColor.toLowerCase()}`}
                to={sect.internal.type === 'ContentfulArt'
                  ? `art/${sect.slug}`
                  : sect.association.internal.type === 'ContentfulArt'
                    ? `art/${sect.association.slug}`
                    : sect.internal.type === 'ContentfulSee'
                      ? `see/${sect.slug}`
                      : sect.association.internal.type === 'ContentfulSee'
                        ? `see/${sect.association.slug}`
                        : ''}
              >
              <GatsbyImage
                className="absolute inset-0"
                alt={sect.summary ?? sect.title}
                image={sect.featuredImage.gatsbyImage}
              />
              </Link>
            </SwiperSlide>
          ))}

          <button
            className="absolute z-10 left-0 inset-y-0 cursor-pointer p-8"
            onClick={() => swiperRef.current?.slidePrev()}
          >
            <img
              src={
                section.highlightColor === 'Black' || section.highlightColor === 'Gray'
                  ? IconDownWhite
                  : IconDownYellow
              }
              alt="Previous Slide"
              className="h-4 rotate-90"
            />
          </button>
          <button
            className="absolute z-10 right-0 inset-y-0 cursor-pointer p-8"
            onClick={() => swiperRef.current?.slideNext()}
          >
            <img
              src={
                section.highlightColor === 'Black' || section.highlightColor === 'Gray'
                  ? IconDownWhite
                  : IconDownYellow
              }
              alt="Next Slide"
              className="h-4 -rotate-90"
            />
          </button>
        </Swiper>
      </div>

      <div className="h-16 flex items-center gap-4">
        <div className="md:w-1/3">
          <h2
            className={joinClassNames(
              section.highlightColor === 'Black' ||
              section.highlightColor === 'Gray'
                ? 'text-white'
                : 'text-black',
              'mr-auto'
            )}
          >
            {section.summary}
          </h2>
        </div>

        <div className="hidden md:flex w-1/3 justify-center gap-2">
          {section.nodes.map((sect, index) => {
              return (
                <button key={index} className={joinClassNames(
                  index === swiperRef.current?.realIndex && (section.highlightColor === 'Black' || section.highlightColor === 'Gray')
                    ? 'bg-white'
                    : index === swiperRef.current?.realIndex ? 'bg-black' : null,
                  section.highlightColor === 'Black' || section.highlightColor === 'Gray' ?
                    'border-white' : 'border-black',
                  'w-3 h-3 rounded-full border'
                )} onClick={() => swiperRef.current?.slideTo(index)}/>
              )
            }
          )}
        </div>

        <div className="md:w-1/3 flex justify-end ml-auto md:ml-unset">
          {!isLast ? (
            <button onClick={() => scrollIntoView(`section${index + 1}`)}>
              {section.highlightColor === 'Black' ||
              section.highlightColor === 'Gray' ? (
                <img
                  src={IconDownWhite}
                  alt="Scroll Down"
                  className="h-4"
                />
              ) : (
                <img
                  src={IconDownBlack}
                  alt="Scroll Down"
                  className="h-4"
                />
              )}
            </button>
          ) : (
            <button onClick={() => scrollIntoView('header')}>
              {section.highlightColor === 'Black' ||
              section.highlightColor === 'Gray' ? (
                <img
                  src={IconDownWhite}
                  alt="Scroll Up"
                  className="h-4 rotate-180"
                />
              ) : (
                <img
                  src={IconDownBlack}
                  alt="Scroll Up"
                  className="h-4 rotate-180"
                />
              )}
            </button>
          )}
        </div>
      </div>
    </InView>
  )
}