import React from "react"
import {Link} from "gatsby";
import joinClassNames from "../utilities/joinClassNames";
import {GatsbyImage} from "gatsby-plugin-image";
import IconDownWhite from "../images/icon-down-white.svg";
import IconDownBlack from "../images/icon-down-black.svg";
import {InView} from "react-intersection-observer";

export default function HomeSectionBanner({ section, setNavbarTheme, index, isLast, scrollIntoView }) {
  return (
    <InView
      as="section"
      threshold={0.9}
      onChange={(inView) => {
        if (
          inView &&
          (section.highlightColor === 'White' ||
            section.highlightColor === 'Yellow')
        ) {
          setNavbarTheme('black');
        } else if (inView) {
          setNavbarTheme('home');
        }
      }}
      className={joinClassNames(
        `bg-${section.highlightColor.toLowerCase()}`,
        'h-[90vh] md:h-screen pt-16 px-8 flex flex-col'
      )}
      id={`section${index}`}
      key={index}
    >
      {section.internal.type === 'ContentfulArt' ||
      section.internal.type === 'ContentfulSee' ||
      (section.internal.type === 'ContentfulEvent' &&
        !!section.association) ? (
        <Link
          className={joinClassNames(
            `cursor-${section.highlightColor.toLowerCase()}`,
            'relative grow'
          )}
          to={
            section.internal.type === 'ContentfulArt'
              ? `art/${section.slug}`
              : section.association.internal.type === 'ContentfulArt'
                ? `art/${section.association.slug}`
                : section.internal.type === 'ContentfulSee'
                  ? `see/${section.slug}`
                  : section.association.internal.type === 'ContentfulSee'
                    ? `see/${section.association.slug}`
                    : ''
          }
        >
          <GatsbyImage
            className="absolute inset-0"
            alt={section.summary}
            image={section.featuredImage.gatsbyImage}
          />
        </Link>
      ) : (
        <div className="relative grow">
          <GatsbyImage
            className="absolute inset-0"
            alt={section.summary}
            image={section.featuredImage.gatsbyImage}
          />
        </div>
      )}

      <div className="h-16 flex items-center">
        <h2
          className={joinClassNames(
            section.highlightColor === 'Black' ||
            section.highlightColor === 'Gray'
              ? 'text-white'
              : 'text-black',
            'mr-auto'
          )}
        >
          {section.summary}
        </h2>

        {!isLast ? (
          <button onClick={() => scrollIntoView(`section${index + 1}`)}>
            {section.highlightColor === 'Black' ||
            section.highlightColor === 'Gray' ? (
              <img
                src={IconDownWhite}
                alt="Scroll Down"
                className="h-4"
              />
            ) : (
              <img
                src={IconDownBlack}
                alt="Scroll Down"
                className="h-4"
              />
            )}
          </button>
        ) : (
          <button onClick={() => scrollIntoView('header')}>
            {section.highlightColor === 'Black' ||
            section.highlightColor === 'Gray' ? (
              <img
                src={IconDownWhite}
                alt="Scroll Up"
                className="h-4 rotate-180"
              />
            ) : (
              <img
                src={IconDownBlack}
                alt="Scroll Up"
                className="h-4 rotate-180"
              />
            )}
          </button>
        )}
      </div>
    </InView>
  )
}