import React, {useRef, useState} from "react"
import {Link} from "gatsby";
import joinClassNames from "../utilities/joinClassNames";
import {GatsbyImage} from "gatsby-plugin-image";
import IconDownWhite from "../images/icon-down-white.svg";
import IconDownYellow from "../images/icon-down-yellow.svg";
import IconDownBlack from "../images/icon-down-black.svg";
import {InView} from "react-intersection-observer";
import {Swiper, SwiperSlide} from "swiper/react";

export default function HomeCollectionCarousel({ collection, setNavbarTheme, index, isLast, scrollIntoView }) {
  const swiperRef = useRef();

  const [collectionColor, setCollectionColor] = useState(
    collection.nodes[0].highlightColor
  );
  const [collectionTitle, setCollectionTitle] = useState(
    collection.nodes[0].title
  );

  return (
    <InView
      as="section"
      threshold={0.9}
      onChange={(inView) => {
        if (
          inView &&
          (collectionColor === 'White' || collectionColor === 'Yellow')
        ) {
          setNavbarTheme('black');
        } else if (inView) {
          setNavbarTheme('home');
        }
      }}
      className={joinClassNames(
        `bg-${collectionColor.toLowerCase()}`,
        'h-[90vh] md:h-screen pt-16 px-8 flex flex-col'
      )}
      id={`section${index}`}
      key={index}
    >

        <div className="relative grow">
          <Swiper
            slidesPerView={1}
            onSlideChange={(swiper) => {
              setCollectionTitle(
                collection.nodes[swiper.realIndex].title
              );
              setCollectionColor(
                collection.nodes[swiper.realIndex].highlightColor
              );
              if (
                collection.nodes[swiper.realIndex]
                  .highlightColor === 'White' ||
                collection.nodes[swiper.realIndex]
                  .highlightColor === 'Yellow'
              ) {
                setNavbarTheme('black');
              } else {
                setNavbarTheme('home');
              }
            }}
            loop={true}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            className="relative w-full h-full"
          >
            {collection.nodes.map((coll, index) => (
              <SwiperSlide key={index} className="w-full h-full">
                <Link
                  className={`cursor-${collectionColor.toLowerCase()}`}
                  to={collection.nodes[0].internal.type === 'ContentfulArt' ? `/art` : `/see`}
                >
                  <GatsbyImage
                    className="absolute inset-0"
                    alt={coll.summary ?? coll.title}
                    image={coll.featuredImage.gatsbyImage}
                  />
                </Link>
              </SwiperSlide>
            ))}

            <button
              className="absolute z-10 left-0 inset-y-0 cursor-pointer p-8"
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <img
                src={
                  collectionColor === 'Black' || collectionColor === 'Gray'
                    ? IconDownWhite
                    : IconDownYellow
                }
                alt="Scroll Down"
                className="h-4 rotate-90"
              />
            </button>
            <button
              className="absolute z-10 right-0 inset-y-0 cursor-pointer p-8"
              onClick={() => swiperRef.current?.slideNext()}
            >
              <img
                src={
                  collectionColor === 'Black' || collectionColor === 'Gray'
                    ? IconDownWhite
                    : IconDownYellow
                }
                alt="Scroll Down"
                className="h-4 -rotate-90"
              />
            </button>
          </Swiper>
        </div>

      <div className="h-16 flex items-center gap-4">
        <div className="md:w-1/3">
          <h2
            className={joinClassNames(
              collectionColor === 'Black' || collectionColor === 'Gray'
                ? 'text-white'
                : 'text-black',
              'truncate'
            )}
          >
            {collection.nodes[0].internal.type === 'ContentfulArt' ? 'Artist' : 'Collection'}: {collectionTitle}
          </h2>
        </div>

        <div className="hidden md:flex w-1/3 justify-center gap-2">
          {collection.nodes.map((coll, index) => {
              return (
                <button key={index} className={joinClassNames(
                  index === swiperRef.current?.realIndex && (collectionColor === 'Black' || collectionColor === 'Gray')
                    ? 'bg-white'
                    : index === swiperRef.current?.realIndex ? 'bg-black' : null,
                  collectionColor === 'Black' || collectionColor === 'Gray' ?
                    'border-white' : 'border-black',
                  'w-3 h-3 rounded-full border'
                )} onClick={() => swiperRef.current?.slideTo(index)}/>
              )
            }
          )}
        </div>

        <div className="md:w-1/3 flex justify-end ml-auto md:ml-unset">
          {!isLast ? (
            <button onClick={() => scrollIntoView(`section${index + 1}`)}>
              {collectionColor === 'Black' ||
              collectionColor === 'Gray' ? (
                <img
                  src={IconDownWhite}
                  alt="Scroll Down"
                  className="h-4"
                />
              ) : (
                <img
                  src={IconDownBlack}
                  alt="Scroll Down"
                  className="h-4"
                />
              )}
            </button>
          ) : (
            <button onClick={() => scrollIntoView('header')}>
              {collectionColor === 'Black' ||
              collectionColor === 'Gray' ? (
                <img
                  src={IconDownWhite}
                  alt="Scroll Up"
                  className="h-4 rotate-180"
                />
              ) : (
                <img
                  src={IconDownBlack}
                  alt="Scroll Up"
                  className="h-4 rotate-180"
                />
              )}
            </button>
          )}
        </div>
      </div>
    </InView>
  )
}